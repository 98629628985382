<template>
  <div>
    <div class="nygy_a_bg">
      <div class="sy_title">
        <h2>我们是谁</h2>
        <b></b>
      </div>
      <div class="nygy_a">
        <div class="gya_z">
          <div style="text-indent:2em">
            UP科技总部位于深圳，并在重庆、湖南、江西等地设立了分公司，获得多地税局授权的委托代征资质，以强大的技术背景自建共享经济灵活用工平台，为用工企业和自由职业者搭建了平等互惠的桥梁。
          </div>
          <div style="text-indent:2em;margin-top: 12px;">
            目前平台已服务超500家企业，覆盖了批发零售、物流配送、电商平台、软件开发等行业。业务场景聚焦在推广服务、信息技术服务、设计服务、直播等。
          </div>
        </div>
        <img
          alt="企业文化"
          src="/img/cultrue.jpg"
        />
      </div>
    </div>

    <div class="nylx_list">
      <div class="sy_title">
        <h2>企业文化</h2>
        <b></b>
      </div>
      <ul style="padding-bottom: 30px;">
        <li>
          <i class="iconfont cultrueIcon">&#xe606;</i>
          <h4>本分</h4>
          <h3>保持求责于己的态度，坚持做正确的事</h3>
        </li>
        <li>
          <i class="iconfont cultrueIcon">&#xe666;</i>
          <h4>品质</h4>
          <h3>品质是精益求精的一种追求</h3>
        </li>
        <li>
          <i class="iconfont cultrueIcon">&#xe63c;</i>
          <h4>诚信</h4>
          <h3>诚实无期，敢于承认错误</h3>
        </li>
      </ul>
        <ul>
        <li>
          <i class="iconfont cultrueIcon">&#xe615;</i>
          <h4>学习</h4>
          <h3>学习型公司，永远保持学习的动力和求知的欲望</h3>
        </li>
        <li>
          <i class="iconfont cultrueIcon">&#xe609;</i>
          <h4>团队</h4>
          <h3>相互信任，坦诚沟通</h3>
        </li>
        <li>
          <i class="iconfont cultrueIcon">&#xe7ad;</i>
          <h4>创新</h4>
          <h3>新思想+能够带来改进或价值的行动</h3>
        </li>
      </ul>
    </div>
   <div class="sy_title">
        <h2>联系我们</h2>
        <b></b>
   </div>
   <div class="waybox mb5">
        <ul>
            <li>
                <div class="icon">
                  <span> <i class="iconfont">&#xe601;</i></span>
                </div>
                <div class="con">
                    <span class="name">立即咨询  <span style="color:#333; padding-left:10px;font-weight:normal;font-size:18px;">杨莉霞</span></span>
                    <p>18675554201</p>
                </div>
            </li>
            <li>
                <div class="icon">
                  <span> <i class="iconfont">&#xe61c;</i></span>
                </div>
                <div class="con">
                    <span class="name">电子邮箱</span>
                    <p>yanglixia@gdbbk.com</p>
                </div>
            </li>
            <div class="clear"></div>
        </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: "contact",
  props: {
    msg: String,
  },
  data() {
    return {
      serverceindex: 0,
      industryindex: 1,
      industryWidth: "240px",
      caseContentIndex: 1,
      industryArr: [
        {
          img: "/img/industry01_s.png",
          imgl: "/img/industry01.png",
          title1: "批发零售",
          title2: "代理商、推广服务等",
          index: 1,
        },
        {
          img: "/img/industry02_s.png",
          imgl: "/img/industry02.png",
          title1: "电商平台",
          title2: "供应链、直播等",
          index: 2,
        },
        {
          img: "/img/industry03_s.png",
          imgl: "/img/industry03.png",
          title1: "物流配送",
          title2: "司机、骑手、装卸服务等",
          index: 3,
        },
        {
          img: "/img/industry04_s.png",
          imgl: "/img/industry04.png",
          title1: "软件开发",
          title2: "技术工程师、信息技术服务等",
          index: 4,
        },
      ],
    };
  },
  mounted() {
  },
  methods: {},
};
</script>

<style scoped>

.nygy_a_bg {
  margin: 30px auto;
  width: 1200px;
}
.nygy_a {
  overflow: hidden;
  margin-top: 20px;
  display: flex;
}
.nygy_a .gya_z {
  flex: 1;
  padding-right: 50px;
  font-size: 18px;
  color: #333;
  line-height: 40px;
}
.nygy_a img {
  width: 580px;
}

.nylx_list {
  overflow: hidden;
  padding: 30px 0 50px 0;
}
.nylx_list ul {
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
}
.nylx_list li {
  width: 400px;
  float: left;
  text-align: center;
  padding-top: 20px;
  /* background: url(../images/d.jpg) repeat-y right; */
}
.nylx_list li img {
  display: block;
  width: 80px;
  margin: 0 auto 10px auto;
  height: 80px;
  /* vertical-align: middle; */
}
.nylx_list li h4 {
  font-size: 18px;
  line-height: 50px;
  height: 50px;
  color: #333;
}
.nylx_list li h3 {
  line-height: 30px;
  font-size: 16px;
  padding: 0 50px;
  color: #999;
}
.cultrueIcon{
font-size: 90px;
color: #3EB2FF;
}
.waybox {
    padding-bottom: 4%;
}
.waybox ul {
  width: 958px;
    margin: 0 auto;
}
.waybox li {
  display: flex;
      align-items: center;
    padding-left: 5%;
    width: 45%;
    float: left;
    box-sizing: border-box;
}
.waybox li:first-child {
border-right: 1px solid #dcdcdc;
}
.waybox li .icon {
  width: 104px;
    height: 104px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    background: #D1E5F2;
}
.icon span{
    width: 85px;
    border-radius: 50%;
    height: 85px;
    line-height: 85px;
    text-align: center;
    display: inline-block;
    background: #3EB2FF;
}
.icon span i {
  font-size: 35px;
    color: #fff;
    font-weight: bold;
}
.waybox li .con {
    display: inline-block;
    vertical-align: middle;
    text-align: left;   
     margin-left: 20px;
}
.waybox li .con .name {
    display: block;
    font-size: 18px;
    line-height: 28px;
}
.waybox li .con p {
    font-size: 24px;
    line-height: 30px;
    color: #3EB2FF;
    font-weight: bold;
}
.clear {
    clear: both;
}

/*  pad pro 1024(pc一样)*/
 @media(min-width:770px) and (max-width:1030px){ 
    .nygy_a_bg{
      width: 90%;
    }
    .nygy_a{
    display: flex;
    }
    .nygy_a .gya_z{
      padding-right: 40px;
      font-size: 16px;
    }
    .nygy_a img{
    width: 370px;
    }
    .nylx_list ul{
      width: 90%;
    }
    .nylx_list li{
       width: 33.33%;
    }
    .waybox li .icon {
  width: 104px;
    height: 104px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    background: #D1E5F2;
}
.icon span{
    width: 85px;
    border-radius: 50%;
    height: 85px;
    line-height: 85px;
    text-align: center;
    display: inline-block;
    background: #3EB2FF;
}
 }

 /* 超小屏幕（手机，小于 768px） */
@media (max-width: 768px) {
 .nygy_a_bg{
      width: 90%;
    }
    .nygy_a{
    display: block;
        margin-top: 0;
    }
    .nygy_a .gya_z{
      padding-right: 0;
      font-size: 16px;
    }
    .nygy_a img{
   display: none;
    }
    .nylx_list {
    padding: 0px 5px 30px 5px;
    }
    .nylx_list ul{
      width: 100%;
      display: flex;
      padding-top: 20px;
    }
    .nylx_list li{
       width: 50%;
    }
    .nylx_list li h4{
      margin: 0;
    line-height: 50px;
    height: auto;
}
.cultrueIcon{
font-size: 60px
}
.nylx_list li h3{
    line-height: 24px;
    font-size: 13px;
    padding: 0 10px;
}
.waybox ul{
  width: 100%;
  max-width: 100%;
  margin: 40px auto;
}
.waybox ul li{
      flex-direction: column;
    align-items: center;
    width: 50%;
    display: flex;
    padding-left: 0;
}
.waybox li .icon{
      width: 80px;
      height: 80px;
      text-align: center;
}
.waybox li .con .name{
  line-height: 50px;
  text-align: center;
}
.waybox li .con p{
    font-size: 15px;
}
.icon span {
        width: 60px;
    height: 60px;
    line-height: 60px;
}
.icon span i {
    font-size: 25px;
}



}
</style>
